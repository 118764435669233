import { DefaultPrivacyLevel } from '@datadog/browser-rum';
import { get } from 'env-var';

export default {
  ENV_NAME: process.env.NEXT_PUBLIC_ENV_NAME,
  APP_DOMAIN: process.env.NEXT_PUBLIC_APP_DOMAIN,
  API_DOMAIN: process.env.NEXT_PUBLIC_API_DOMAIN,
  IMAGE_CDN_HOST: process.env.NEXT_PUBLIC_IMAGE_CDN_HOST,
  REVALIDATE_SHORT: +(process.env.NEXT_PUBLIC_REVALIDATE_SHORT || 10),
  REVALIDATE_MEDIUM: +(process.env.NEXT_PUBLIC_REVALIDATE_MEDIUM || 60),
  REVALIDATE_LONG: +(process.env.NEXT_PUBLIC_REVALIDATE_LONG || 3600),
  GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
  GTM_ID_WITHOUT_PREFIX: process.env.NEXT_PUBLIC_GTM_ID?.replace('GTM-', ''),
  GTM_AUTH: process.env.NEXT_PUBLIC_GTM_AUTH,
  GTM_PREVIEW: process.env.NEXT_PUBLIC_GTM_PREVIEW,
  GTM_CDN_HOST: process.env.NEXT_PUBLIC_GTM_CDN_HOST || 'https://www.googletagmanager.com',
  RECAPTCHA_SITE_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || '',
  CPD_DOMAIN: process.env.NEXT_PUBLIC_CPD_DOMAIN || '',
  IDENTITY_SERVER_DOMAIN: process.env.NEXT_PUBLIC_IDENTITY_SERVER_DOMAIN || '',
  APP_VERSION: process.env.NEXT_PUBLIC_APP_VERSION || '0.0.0',
  DATADOG_ENABLE: process.env.NEXT_PUBLIC_DATADOG_ENABLE === 'true',
  DATADOG_APPLICATION_ID: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID || '',
  DATADOG_CLIENT_TOKEN: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN || '',
  DATADOG_SITE: process.env.NEXT_PUBLIC_DATADOG_SITE || 'datadoghq.com',
  DATADOG_SERVICE_NAME: process.env.NEXT_PUBLIC_DATADOG_SERVICE_NAME || 'retail',
  DATADOG_SAMPLE_RATE: +(process.env.NEXT_PUBLIC_DATADOG_SAMPLE_RATE || 100),
  DATADOG_SESSION_ON_REPLAY_SAMPLE_RATE: +(process.env.NEXT_PUBLIC_DATADOG_SESSION_ON_REPLAY_SAMPLE_RATE || 20),
  DATADOG_SESSION_TRACK_INTERACTIONS_ENABLE:
    process.env.NEXT_PUBLIC_DATADOG_SESSION_TRACK_INTERACTIONS_ENABLE === 'true',
  DATADOG_SESSION_TRACK_RESOURCES_ENABLE: process.env.NEXT_PUBLIC_DATADOG_SESSION_TRACK_RESOURCES_ENABLE === 'true',
  DATADOG_SESSION_TRACK_LONG_TASKS_ENABLE: process.env.NEXT_PUBLIC_DATADOG_SESSION_TRACK_LONG_TASKS_ENABLE === 'true',
  DATADOG_SESSION_DEFAULT_PRIVACY_LEVEL: (process.env.NEXT_PUBLIC_DATADOG_SESSION_DEFAULT_PRIVACY_LEVEL ||
    DefaultPrivacyLevel.MASK_USER_INPUT) as DefaultPrivacyLevel,
  PRECONNECT_LINKS: get('NEXT_PUBLIC_PRECONNECT_LINKS')
    .default('')
    .asArray(),
};
