import { all } from 'redux-saga/effects';

import appSaga from './app/saga';
import degreeSaga from './degree/saga';
import enrollmentSaga from './enrollment/saga';
import interactiveContentSaga from './interactiveContent/saga';
import learningSaga from './learning/saga';
import universitySaga from './university/saga';
import userSaga from './user/saga';

import manageUserSaga from '@redux/manageUser/saga';
import manageUserIdentitySaga from '@redux/manageUserIdentity/saga';
import userIdentitySaga from '@redux/userIdentity/saga';

export default function* rootSaga() {
  yield all([
    appSaga(),
    enrollmentSaga(),
    learningSaga(),
    userSaga(),
    interactiveContentSaga(),
    degreeSaga(),
    universitySaga(),
    userIdentitySaga(),
    manageUserSaga(),
    manageUserIdentitySaga(),
  ]);
}
