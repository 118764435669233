import { Record } from 'immutable';

import actions from './actions';

import { Grade, UniversityEvaluationResult } from 'src/types/enrollment';

type EnrollmentStateType = {
  university_evaluation_results: Array<UniversityEvaluationResult>;
  university_evaluation_results_total: number;
  university_evaluation_results_loading: boolean;
  university_evaluation_result_detail: UniversityEvaluationResult | null;
  update_evaluation_results_loading: boolean;

  courseGrades: Array<Grade>;
};

type EnrollmentReducerType = {
  state?: EnrollmentStateType;
  type?: string;
  payload?: any;
};

const initState = new (Record<EnrollmentStateType>({
  university_evaluation_results: [],
  university_evaluation_results_total: 0,
  university_evaluation_results_loading: false,
  university_evaluation_result_detail: null,
  update_evaluation_results_loading: false,

  courseGrades: [],
}))();

export default function EnrollmentReducer(state = initState, { type, payload }: EnrollmentReducerType) {
  switch (type) {
    case actions.FETCH_UNIVERSITY_EVALUATION_RESULT_LIST_REQUEST:
      return state.set('university_evaluation_results_loading', true);
    case actions.FETCH_UNIVERSITY_EVALUATION_RESULT_LIST_SUCCESS:
      return state
        .set('university_evaluation_results', payload.items)
        .set('university_evaluation_results_total', payload.total)
        .set('university_evaluation_results_loading', false);
    case actions.FETCH_UNIVERSITY_EVALUATION_RESULT_LIST_ERROR:
      return state
        .set('university_evaluation_results', [])
        .set('university_evaluation_results_total', 0)
        .set('university_evaluation_results_loading', false);

    case actions.FETCH_UNIVERSITY_EVALUATION_RESULT_DETAIL_REQUEST:
      return state.set('university_evaluation_result_detail', null);
    case actions.FETCH_UNIVERSITY_EVALUATION_RESULT_DETAIL_SUCCESS:
      return state.set('university_evaluation_result_detail', payload);
    case actions.FETCH_UNIVERSITY_EVALUATION_RESULT_DETAIL_ERROR:
      return state.set('university_evaluation_result_detail', null);

    case actions.FETCH_COURSE_GRADES_REQUEST:
      return state.set('courseGrades', []);
    case actions.FETCH_COURSE_GRADES_SUCCESS:
      return state.set('courseGrades', payload);
    case actions.FETCH_COURSE_GRADES_ERROR:
      return state.set('courseGrades', []);

    case actions.UPDATE_UNIVERSITY_EVALUATION_RESULT_REQUEST:
      return state.set('update_evaluation_results_loading', true);
    case actions.UPDATE_UNIVERSITY_EVALUATION_RESULT_SUCCESS:
      return state.set('update_evaluation_results_loading', false);
    case actions.UPDATE_UNIVERSITY_EVALUATION_RESULT_ERROR:
      return state.set('update_evaluation_results_loading', false);

    default:
      return state;
  }
}
