import { all, takeEvery, call, put, fork, select } from 'redux-saga/effects';

import actions from './actions';

import { ICoreApiResponse } from '@interfaces/common';
import { CreateOrUpdateFinishedLogResponse, ILearningPageCurriculum } from '@interfaces/learning';
import { IQuizSubmission, StartQuizParams } from '@interfaces/quiz';
import { RootState } from '@redux/reducer';
import courseAPI from 'src/api/course';
import enrollmentAPI from 'src/api/enrollment';
import learningAPI from 'src/api/learning';
import { LearningStatusCode } from 'src/helpers/constants';
import { ResponseDataV2 } from 'src/helpers/httpClient';
import learningActions from 'src/redux/learning/actions';

const { clear_quiz_summary_score } = learningActions;

const getLearning = (state: RootState) => state.Learning;

export function* fetch_learning_course() {
  yield takeEvery(actions.FETCH_LEARNING_COURSE_REQUEST, function*(payload: any) {
    const apiResponse: ResponseDataV2<ICoreApiResponse<ILearningPageCurriculum, LearningStatusCode>> = yield call(
      learningAPI.fetchLearningCourse,
      payload,
    );

    if (apiResponse.status === 401) {
      yield put({
        type: actions.FETCH_LEARNING_COURSE_ERROR,
        data: apiResponse.data,
        status: 'UNAUTHORIZED',
      });
    }

    if (apiResponse.data?.status === '000') {
      yield put({
        type: actions.FETCH_LEARNING_COURSE_SUCCESS,
        data: apiResponse.data.data,
        status: apiResponse.data.status,
      });
      yield put(clear_quiz_summary_score());
    } else {
      yield put({
        type: actions.FETCH_LEARNING_COURSE_ERROR,
        data: apiResponse.data?.data,
        status: apiResponse.data?.status || apiResponse.status,
      });
    }
  });
}

export function* set_user_answers() {
  yield takeEvery(actions.SET_USER_ANSWERS_REQUEST, function*(payload: any) {
    const { quizSubmissionId, quizSubmission } = payload;
    const apiResponse: ResponseDataV2<ICoreApiResponse<
      any,
      LearningStatusCode
    >> = yield call(learningAPI.submitQuizAnswer, { quizSubmissionId, quizSubmission });
    if (apiResponse.data?.status === '000') {
      yield put({
        type: actions.SET_USER_ANSWERS_SUCCESS,
        data: apiResponse.data.data,
        status: apiResponse.data.status,
      });
    } else {
      yield put({
        type: actions.SET_USER_ANSWERS_ERROR,
        data: apiResponse.data?.data,
        status: apiResponse.data?.status || apiResponse.status,
      });
    }
  });
}

export function* get_quiz_summary_score() {
  yield takeEvery(actions.FETCH_QUIZ_SUMMARY_SCORE_REQUEST, function*(payload: any) {
    const apiResponse: ResponseDataV2<ICoreApiResponse<any, LearningStatusCode>> = yield call(
      learningAPI.fetchQuizSummaryScore,
      payload,
    );

    if (apiResponse.data?.status === '000') {
      yield put({
        type: actions.FETCH_QUIZ_SUMMARY_SCORE_SUCCESS,
        data: apiResponse.data.data,
      });
    } else {
      yield put({
        type: actions.FETCH_QUIZ_SUMMARY_SCORE_ERROR,
        data: apiResponse.data?.data,
      });
    }
  });
}

export function* create_or_update_finished_log() {
  yield takeEvery(actions.CREATE_OR_UPDATE_FINISHED_LOG_REQUEST, function*(payload: any) {
    const apiResponse: ResponseDataV2<ICoreApiResponse<
      CreateOrUpdateFinishedLogResponse,
      LearningStatusCode
    >> = yield call(learningAPI.createOrUpdateFinishedLog, {
      courseIdOrIdName: payload.courseIdOrIdName,
      finishedLogPayload: payload.data,
    });

    if (apiResponse.data?.status === '000') {
      yield put({
        type: actions.CREATE_OR_UPDATE_FINISHED_LOG_SUCCESS,
        data: apiResponse.data.data,
        status: apiResponse.data.status,
      });
    } else {
      yield put({
        type: actions.CREATE_OR_UPDATE_FINISHED_LOG_ERROR,
      });
    }
  });
}

export function* get_quiz_submission() {
  yield takeEvery(actions.GET_QUIZ_SUBMISSION_REQUEST, function*(payload: any) {
    const apiResponse: ResponseDataV2<ICoreApiResponse<IQuizSubmission, LearningStatusCode>> = yield call(
      learningAPI.getQuizSubmission,
      payload.quizId,
    );

    if (apiResponse.data?.status === '000') {
      yield put({
        type: actions.GET_QUIZ_SUBMISSION_SUCCESS,
        data: apiResponse.data.data,
        status: apiResponse.data.status,
      });
    } else {
      yield put({
        type: actions.GET_QUIZ_SUBMISSION_ERROR,
      });
    }
  });
}
// StartQuizParams
export function* start_quiz(query: { type: string; payload: StartQuizParams }) {
  const { payload } = query;
  const apiResponse: ResponseDataV2<ICoreApiResponse<IQuizSubmission, LearningStatusCode>> = yield call(
    learningAPI.startQuiz,
    payload,
  );

  if (apiResponse.data?.status === '000') {
    yield put({
      type: actions.START_QUIZ_REQUEST_SUCCESS,
      data: apiResponse.data.data,
      status: apiResponse.data.status,
    });
  } else {
    yield put({
      type: actions.START_QUIZ_REQUEST_ERROR,
      data: apiResponse.data?.message,
    });
  }
}

export function* create_certificate() {
  yield takeEvery(actions.CREATE_CERTIFICATE_REQUEST, function*(payload: any) {
    const apiResponse: ResponseDataV2<ICoreApiResponse<
      CreateOrUpdateFinishedLogResponse,
      LearningStatusCode
    >> = yield call(enrollmentAPI.createCertificate, {
      createCertificatePayload: payload.createCertificatePayload,
    });

    if (apiResponse.data?.status === '000') {
      yield put({
        type: actions.CREATE_CERTIFICATE_SUCCESS,
        data: apiResponse.data.data,
        status: apiResponse.data.status,
      });
    } else {
      yield put({
        type: actions.CREATE_CERTIFICATE_ERROR,
      });
    }
  });
}

export function* fetch_reviews() {
  yield takeEvery(actions.FETCH_REVIEWS_REQUEST, function*(payload: any) {
    const apiResponse: ResponseDataV2<ICoreApiResponse<
      CreateOrUpdateFinishedLogResponse,
      LearningStatusCode
    >> = yield call(courseAPI.fetchReviews, {
      limit: payload.limit,
      courseIdOrIdName: payload.courseIdOrIdName,
    });
    if (apiResponse.data?.status === '000') {
      yield put({
        type: actions.FETCH_REVIEWS_SUCCESS,
        data: apiResponse.data.data,
        status: apiResponse.data.status,
      });
    } else {
      yield put({
        type: actions.FETCH_REVIEWS_ERROR,
      });
    }
  });
}

export function* create_or_update_review() {
  yield takeEvery(actions.CREATE_OR_UPDATE_REVIEW_REQUEST, function*(payload: any) {
    const apiResponse: ResponseDataV2<ICoreApiResponse<
      CreateOrUpdateFinishedLogResponse,
      LearningStatusCode
    >> = yield call(courseAPI.createOrUpdateReview, {
      createOrUpdateReviewPayload: {
        rating: payload.rating,
        comment: payload.comment,
      },
      courseIdOrIdName: payload.courseIdOrIdName,
    });
    if (apiResponse.data?.status === '000') {
      yield put({
        type: actions.CREATE_OR_UPDATE_REVIEW_SUCCESS,
        data: apiResponse.data.data,
        status: apiResponse.data.status,
      });
    } else {
      yield put({
        type: actions.CREATE_OR_UPDATE_REVIEW_ERROR,
      });
    }
  });
}

export function* fetch_reviews_summary() {
  yield takeEvery(actions.FETCH_REVIEWS_SUMMARY_REQUEST, function*(payload: any) {
    const apiResponse: ResponseDataV2<ICoreApiResponse<
      CreateOrUpdateFinishedLogResponse,
      LearningStatusCode
    >> = yield call(courseAPI.fetchReviewsSummary, {
      courseIdOrIdName: payload.courseIdOrIdName,
    });
    if (apiResponse.data?.status === '000') {
      yield put({
        type: actions.FETCH_REVIEWS_SUMMARY_SUCCESS,
        data: apiResponse.data.data,
        status: apiResponse.data.status,
      });
    } else {
      yield put({
        type: actions.FETCH_REVIEWS_SUMMARY_ERROR,
      });
    }
  });
}

export function* update_review_skip_log() {
  yield takeEvery(actions.UPDATE_REVIEW_SKIP_LOG_REQUEST, function*(payload: any) {
    const apiResponse: ResponseDataV2<ICoreApiResponse<void, LearningStatusCode>> = yield call(
      courseAPI.updateReviewSkipLog,
      {
        courseIdOrIdName: payload.courseIdOrIdName,
      },
    );
    if (apiResponse.data?.status === '000') {
      yield put({
        type: actions.UPDATE_REVIEW_SKIP_LOG_SUCCESS,
        status: apiResponse.data.status,
      });
    } else {
      yield put({
        type: actions.UPDATE_REVIEW_SKIP_LOG_ERROR,
      });
    }
  });
}

export function* fetch_discussion(payload: {
  type: string;
  courseIdOrIdName: string | number;
  params: { limit: number; keyword?: string; discussionId?: number };
}) {
  const { courseIdOrIdName, params } = payload;
  const apiResponse: ResponseDataV2<ICoreApiResponse<IQuizSubmission, LearningStatusCode>> = yield call(
    courseAPI.fetchDiscussion,
    courseIdOrIdName,
    params,
  );

  if (apiResponse.data?.status === '000') {
    yield put({
      type: actions.FETCH_DISCUSSION_SUCCESS,
      data: apiResponse.data.data,
      status: apiResponse.data.status,
    });
  } else {
    yield put({
      type: actions.FETCH_DISCUSSION_ERROR,
    });
  }
}

export function* create_discussion(data: {
  type: string;
  courseIdOrIdName: string | number;
  payload: { title: string };
}) {
  const { courseIdOrIdName, payload } = data;
  const apiResponse: ResponseDataV2<ICoreApiResponse<IQuizSubmission, LearningStatusCode>> = yield call(
    courseAPI.createDiscussion,
    courseIdOrIdName,
    payload,
  );

  if (apiResponse.data?.status === '000') {
    yield put({
      type: actions.CREATE_DISCUSSION_SUCCESS,
      data: apiResponse.data.data,
      status: apiResponse.data.status,
    });
  } else {
    yield put({
      type: actions.CREATE_DISCUSSION_ERROR,
    });
  }
}

export function* create_discussion_reply(data: {
  type: string;
  courseIdOrIdName: string;
  discussionId: number;
  params: { limit: number; keyword?: string };
  payload: { content: string };
}) {
  const { courseIdOrIdName, discussionId, params, payload } = data;
  const apiResponse: ResponseDataV2<ICoreApiResponse<IQuizSubmission, LearningStatusCode>> = yield call(
    courseAPI.createDiscussionReply,
    courseIdOrIdName,
    discussionId,
    payload,
  );

  if (apiResponse.data?.status === '000') {
    yield put(learningActions.fetch_discussion(courseIdOrIdName, params));
    yield put({
      type: actions.CREATE_DISCUSSION_REPLY_SUCCESS,
      data: apiResponse.data.data,
      status: apiResponse.data.status,
    });
  } else {
    yield put({
      type: actions.CREATE_DISCUSSION_REPLY_ERROR,
    });
  }
}

export default function* rootSaga() {
  yield all([
    fork(fetch_learning_course),
    fork(set_user_answers),
    fork(get_quiz_summary_score),
    fork(create_or_update_finished_log),
    fork(get_quiz_submission),
    takeEvery(actions.START_QUIZ_REQUEST, start_quiz),
    fork(create_certificate),
    fork(fetch_reviews),
    fork(create_or_update_review),
    fork(fetch_reviews_summary),
    fork(update_review_skip_log),
    takeEvery(actions.FETCH_DISCUSSION_REQUEST, fetch_discussion),
    takeEvery(actions.CREATE_DISCUSSION_REQUEST, create_discussion),
    takeEvery(actions.CREATE_DISCUSSION_REPLY_REQUEST, create_discussion_reply),
  ]);
}
