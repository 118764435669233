import { EditedUniversityEvaluationResult, EvaluationResultQuery } from 'src/types/enrollment';

const actions = {
  FETCH_UNIVERSITY_EVALUATION_RESULT_LIST_REQUEST: 'FETCH_UNIVERSITY_EVALUATION_RESULT_LIST_REQUEST',
  FETCH_UNIVERSITY_EVALUATION_RESULT_LIST_SUCCESS: 'FETCH_UNIVERSITY_EVALUATION_RESULT_LIST_SUCCESS',
  FETCH_UNIVERSITY_EVALUATION_RESULT_LIST_ERROR: 'FETCH_UNIVERSITY_EVALUATION_RESULT_LIST_ERROR',

  FETCH_UNIVERSITY_EVALUATION_RESULT_DETAIL_REQUEST: 'FETCH_UNIVERSITY_EVALUATION_RESULT_DETAIL_REQUEST',
  FETCH_UNIVERSITY_EVALUATION_RESULT_DETAIL_SUCCESS: 'FETCH_UNIVERSITY_EVALUATION_RESULT_DETAIL_SUCCESS',
  FETCH_UNIVERSITY_EVALUATION_RESULT_DETAIL_ERROR: 'FETCH_UNIVERSITY_EVALUATION_RESULT_DETAIL_ERROR',

  FETCH_COURSE_GRADES_REQUEST: 'FETCH_COURSE_GRADES_REQUEST',
  FETCH_COURSE_GRADES_SUCCESS: 'FETCH_COURSE_GRADES_SUCCESS',
  FETCH_COURSE_GRADES_ERROR: 'FETCH_COURSE_GRADES_ERROR',

  UPDATE_UNIVERSITY_EVALUATION_RESULT_REQUEST: 'UPDATE_UNIVERSITY_EVALUATION_RESULT_REQUEST',
  UPDATE_UNIVERSITY_EVALUATION_RESULT_SUCCESS: 'UPDATE_UNIVERSITY_EVALUATION_RESULT_SUCCESS',
  UPDATE_UNIVERSITY_EVALUATION_RESULT_ERROR: 'UPDATE_UNIVERSITY_EVALUATION_RESULT_ERROR',

  fetch_university_evaluation_result_list: (query: EvaluationResultQuery) => ({
    type: actions.FETCH_UNIVERSITY_EVALUATION_RESULT_LIST_REQUEST,
    query,
  }),
  fetch_university_evaluation_result_detail: (id: number) => ({
    type: actions.FETCH_UNIVERSITY_EVALUATION_RESULT_DETAIL_REQUEST,
    id,
  }),
  fetch_course_grades: (id: number) => ({
    type: actions.FETCH_COURSE_GRADES_REQUEST,
    id,
  }),
  update_university_evaluation_result: (data: EditedUniversityEvaluationResult, callback: any) => ({
    type: actions.UPDATE_UNIVERSITY_EVALUATION_RESULT_REQUEST,
    data,
    callback,
  }),
};

export default actions;
