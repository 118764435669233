import Router from 'next/router';

import { all, call, takeEvery, put } from 'redux-saga/effects';

import actions from './actions';
import enrollmentAPI from './api';

import { ROUTES } from 'src/helpers/constants';
import { ResponseData } from 'src/helpers/httpClient';
import { HttpStatus } from 'src/types/common';
import { EditedUniversityEvaluationResult, EvaluationResultQuery } from 'src/types/enrollment';

type Payload = {
  type: string;
};

function* fetch_university_evaluation_result_list(payload: { query: EvaluationResultQuery } & Payload) {
  const { query } = payload;
  const response: ResponseData = yield call(enrollmentAPI.fetchUniversityEvaluationResultList, query);
  if (response.status === HttpStatus.OK) {
    yield put({
      type: actions.FETCH_UNIVERSITY_EVALUATION_RESULT_LIST_SUCCESS,
      payload: response.data,
    });
  } else if (response.status === 401) {
    // TODO: implement refresh token
    window.location.reload();
    yield put({
      type: actions.FETCH_UNIVERSITY_EVALUATION_RESULT_LIST_ERROR,
    });
  } else {
    yield put({
      type: actions.FETCH_UNIVERSITY_EVALUATION_RESULT_LIST_ERROR,
    });
  }
}

function* fetch_university_evaluation_result_detail(payload: { id: number } & Payload) {
  const { id } = payload;
  const response: ResponseData = yield call(enrollmentAPI.fetchUniversityEvaluationResultDetail, id);
  if (response.status === HttpStatus.OK) {
    yield put({
      type: actions.FETCH_UNIVERSITY_EVALUATION_RESULT_DETAIL_SUCCESS,
      payload: response.data,
    });

    yield put({
      type: actions.FETCH_COURSE_GRADES_REQUEST,
      id: response.data.course_id,
    });
  } else if ([HttpStatus.FORBIDDEN, HttpStatus.NOT_FOUND].includes(response.status)) {
    yield put({
      type: actions.FETCH_UNIVERSITY_EVALUATION_RESULT_DETAIL_ERROR,
    });
    Router.push(ROUTES.EVALUATION_RESULT_LIST);
  } else if (response.status === HttpStatus.UNAUTHORIZED) {
    // TODO: implement refresh token
    window.location.reload();
    yield put({
      type: actions.FETCH_UNIVERSITY_EVALUATION_RESULT_DETAIL_ERROR,
    });
  } else {
    yield put({
      type: actions.FETCH_UNIVERSITY_EVALUATION_RESULT_DETAIL_ERROR,
    });
  }
}

function* fetch_course_grades(payload: { id: number } & Payload) {
  const { id } = payload;
  const response: ResponseData = yield call(enrollmentAPI.fetchCourseGrades, id);
  if (response.status === HttpStatus.OK) {
    yield put({
      type: actions.FETCH_COURSE_GRADES_SUCCESS,
      payload: response.data,
    });
  } else {
    yield put({
      type: actions.FETCH_COURSE_GRADES_ERROR,
    });
  }
}

function* update_university_evaluation_result(
  payload: { data: EditedUniversityEvaluationResult; callback: { success: Function; error: Function } } & Payload,
) {
  const { data, callback } = payload;
  const response: ResponseData = yield call(enrollmentAPI.updateUniversityEvaluationResult, data);
  if (response.status === HttpStatus.OK) {
    yield put({
      type: actions.UPDATE_UNIVERSITY_EVALUATION_RESULT_SUCCESS,
    });
    yield put({
      type: actions.FETCH_UNIVERSITY_EVALUATION_RESULT_DETAIL_SUCCESS,
      payload: response.data,
    });
    callback.success();
  } else {
    yield put({
      type: actions.UPDATE_UNIVERSITY_EVALUATION_RESULT_ERROR,
    });
    callback.error();
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_UNIVERSITY_EVALUATION_RESULT_LIST_REQUEST, fetch_university_evaluation_result_list),
  ]);
  yield all([
    takeEvery(actions.FETCH_UNIVERSITY_EVALUATION_RESULT_DETAIL_REQUEST, fetch_university_evaluation_result_detail),
  ]);
  yield all([takeEvery(actions.FETCH_COURSE_GRADES_REQUEST, fetch_course_grades)]);
  yield all([takeEvery(actions.UPDATE_UNIVERSITY_EVALUATION_RESULT_REQUEST, update_university_evaluation_result)]);
}
