import { Record } from 'immutable';

import actions from './actions';

import { updateQuery } from 'src/helpers/utils';
import { CourseAPIQueryProps } from 'src/interfaces/course';

export type CourseStateType = {
  courseApiQueries: CourseAPIQueryProps;
};

type CourseReducerType = {
  state?: CourseStateType;
  type?: string;
  payload?: any;
};

const initState = new (Record<CourseStateType>({
  courseApiQueries: {},
}))();

export default function CourseReducer(state = initState, { type, payload }: CourseReducerType) {
  switch (type) {
    case actions.ADD_COURSE_API_QUERY:
      const query = { ...state.courseApiQueries, ...payload };
      if (Object.keys(query).length) {
        const convertQuery = Object.keys(query).reduce((acc: any, key: string) => {
          if (query[key].length) {
            acc[key] = query[key].join();
          } else {
            delete acc[key];
          }
          return acc;
        }, {});
        updateQuery(convertQuery);
      }
      const removeQueryEmpty = Object.keys(query).reduce((acc: any, key: string) => {
        if (query[key].length) {
          acc[key] = query[key];
        } else {
          delete acc[key];
        }
        return acc;
      }, {});
      return state.set('courseApiQueries', removeQueryEmpty);
    case actions.SET_COURSE_API_SORT_ORDER:
      updateQuery(payload);
      return state.set('courseApiQueries', payload);
    case actions.CLEAR_COURSE_API_QUERY: {
      let update: CourseAPIQueryProps = {};
      if (state.courseApiQueries.orderBy) {
        const sort = { sortBy: state.courseApiQueries.sortBy, orderBy: state.courseApiQueries.orderBy };
        update = { ...update, sortBy: sort.sortBy, orderBy: sort.orderBy };
      }
      return state.set('courseApiQueries', update);
    }
    case actions.CLEAR_COURSE_FILTER: {
      const clearCourseFilter = () => {
        let courseAPIQueryProps: CourseAPIQueryProps = {};
        if (state.courseApiQueries.q) {
          courseAPIQueryProps = { ...courseAPIQueryProps, q: state.courseApiQueries.q };
        }
        if (state.courseApiQueries.orderBy) {
          const sort = { sortBy: state.courseApiQueries.sortBy, orderBy: state.courseApiQueries.orderBy };
          courseAPIQueryProps = { ...courseAPIQueryProps, sortBy: sort.sortBy, orderBy: sort.orderBy };
        }
        return courseAPIQueryProps;
      };
      const reset = clearCourseFilter();
      updateQuery(reset);
      return state.set('courseApiQueries', reset);
    }
    default:
      return state;
  }
}
