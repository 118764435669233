import { CreateCertificatePayload } from '@interfaces/learning';
import config from 'src/config';
import Http from 'src/helpers/httpClient';
import { EditedUniversityEvaluationResult, EvaluationResultQuery } from 'src/types/enrollment';

const END_POINT = 'v1/enrollments';

export const ENROLLMENT_END_POINT = {
  ENROLLMENTS_ME: `${END_POINT}/me`,
  ENROLLMENTS_FREE: `${END_POINT}/free`,
  CREATE_CERTIFICATE: `${END_POINT}/certificate`,
};

const querySearchEvaluationResult = (query: EvaluationResultQuery) => {
  const { page, limit, email, studentId, courseName } = query;
  let queryString = '';

  if (email) {
    queryString += `email=${encodeURIComponent(email)}&`;
  }

  if (studentId) {
    queryString += `studentId=${studentId}&`;
  }

  if (courseName) {
    queryString += `courseName=${courseName}&`;
  }

  if (page) {
    queryString += `page=${page}&`;
  }

  if (limit) {
    queryString += `limit=${limit}`;
  }

  return queryString;
};

const api = {
  fetchUniversityEvaluationResultList(query: EvaluationResultQuery) {
    const apiUrl = `${config.API_DOMAIN}/v1/university/evaluation-results?${querySearchEvaluationResult(query)}`;
    return Http.get(apiUrl);
  },
  fetchUniversityEvaluationResultDetail(id: number) {
    const apiUrl = `${config.API_DOMAIN}/v1/university/evaluation-results/${id}`;
    return Http.get(apiUrl);
  },
  fetchCourseGrades(id: number) {
    const apiUrl = `${config.API_DOMAIN}/v1/courses/${id}/grades`;
    return Http.get(apiUrl);
  },
  updateUniversityEvaluationResult(data: EditedUniversityEvaluationResult) {
    const apiUrl = `${config.API_DOMAIN}/v1/university/evaluation-results/${data.id}`;
    return Http.put(apiUrl, data);
  },

  fetchCoursesEnrollment() {
    const apiUrl = `${config.API_DOMAIN}/${ENROLLMENT_END_POINT.ENROLLMENTS_ME}`;
    return Http.get(apiUrl);
  },
  createUserEnrollFree(data: { course_id: number }) {
    const apiUrl = `${config.API_DOMAIN}/${ENROLLMENT_END_POINT.ENROLLMENTS_FREE}`;
    return Http.post(apiUrl, data);
  },
  createCertificate(payload: { createCertificatePayload: CreateCertificatePayload }) {
    const { createCertificatePayload } = payload;
    const apiUrl = `${config.API_DOMAIN}/${ENROLLMENT_END_POINT.CREATE_CERTIFICATE}`;
    return Http.post(apiUrl, createCertificatePayload);
  },
};

export default api;
