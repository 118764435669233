const actions = {
  ADD_COURSE_API_QUERY: 'ADD_COURSE_API_QUERY',
  SET_COURSE_API_SORT_ORDER: 'SET_COURSE_API_SORT_ORDER',
  CLEAR_COURSE_API_QUERY: 'CLEAR_COURSE_API_QUERY',
  CLEAR_COURSE_FILTER: 'CLEAR_COURSE_FILTER',

  add_course_api_query: (queryString: any) => ({
    type: actions.ADD_COURSE_API_QUERY,
    payload: queryString,
  }),

  set_course_api_sort_order: (sortOrder: any) => ({
    type: actions.SET_COURSE_API_SORT_ORDER,
    payload: sortOrder,
  }),

  clear_course_api_query: () => ({
    type: actions.CLEAR_COURSE_API_QUERY,
  }),

  clear_course_filter: () => ({
    type: actions.CLEAR_COURSE_FILTER,
  }),
};
export default actions;
