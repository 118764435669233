import { combineReducers } from 'redux';

import App from './app/reducer';
import Course from './course/reducer';
import Degree from './degree/reducer';
import Enrollment from './enrollment/reducer';
import InteractContent from './interactiveContent/reducer';
import Learning from './learning/reducer';
import manageUserIdentityReducer from './manageUserIdentity/reducer';
import University from './university/reducer';
import User from './user/reducer';

import manageUserReducer from '@redux/manageUser/reducer';
import userIdentityReducer from '@redux/userIdentity/reducer';

const combinedReducers = combineReducers({
  App,
  Enrollment,
  Course,
  Learning,
  User,
  InteractContent,
  Degree,
  University,
  UserIdentity: userIdentityReducer,
  ManageUser: manageUserReducer,
  ManageUserIdentity: manageUserIdentityReducer,
});

export default combinedReducers;

export type RootState = ReturnType<typeof combinedReducers>;
