import config from 'src/config';
import Http from 'src/helpers/httpClient';
import { EditedUniversityEvaluationResult, EvaluationResultQuery } from 'src/types/enrollment';

const querySearchEvaluationResult = (query: EvaluationResultQuery) => {
  const { page, limit, email, studentId, courseName } = query;
  let queryString = '';

  if (email) {
    queryString += `email=${encodeURIComponent(email)}&`;
  }

  if (studentId) {
    queryString += `studentId=${studentId}&`;
  }

  if (courseName) {
    queryString += `courseName=${courseName}&`;
  }

  if (page) {
    queryString += `page=${page}&`;
  }

  if (limit) {
    queryString += `limit=${limit}`;
  }

  return queryString;
};

const api = {
  fetchUniversityEvaluationResultList(query: EvaluationResultQuery) {
    const apiUrl = `${config.API_DOMAIN}/v1/university/evaluation-results?${querySearchEvaluationResult(query)}`;
    return Http.get(apiUrl);
  },
  fetchUniversityEvaluationResultDetail(id: number) {
    const apiUrl = `${config.API_DOMAIN}/v1/university/evaluation-results/${id}`;
    return Http.get(apiUrl);
  },
  fetchCourseGrades(id: number) {
    const apiUrl = `${config.API_DOMAIN}/v1/courses/${id}/grades`;
    return Http.get(apiUrl);
  },
  updateUniversityEvaluationResult(data: EditedUniversityEvaluationResult) {
    const apiUrl = `${config.API_DOMAIN}/v1/university/evaluation-results/${data.id}`;
    return Http.put(apiUrl, data);
  },
};

export default api;
